import {
  Application,
  AuthProvider,
  CheckKeycloakInstance,
  GlobalModalProvider,
  TenantProvider
} from "@likemagic-tech/sv-magic-library";
import { HelmetProvider } from "react-helmet-async";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { SettingsProvider } from "./contexts/SettingsContext";
import { BrowserRouter } from "react-router-dom";
import { FC, PropsWithChildren, StrictMode } from "react";
import { FaviconSetter } from "./components/favicon/favicon-setter";
import { CssBaseline } from "@mui/material";
import { AppLoaders } from "./features/loaders/app-loaders";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import { GraphqlTenantHeadersSetup } from "./graphql/graphql-tenant-headers-setup";
import { InitI18n } from "./config/i18n/core/init-i18n";
import { LocalizationProvider } from "@mui/lab";
import { InjectContextTenantIntoRedux } from "./api/component/inject-context-tenant-into-redux";
import { MetaUpdate } from "./utils/meta-update";
import { ThemeProvider } from "./config/theme/theme-provider";
import GlobalStyles from "./components/GlobalStyles";

export const AppProviders: FC<PropsWithChildren<PropsWithChildren>> = ({ children }) => {
  return (
    <TenantProvider application={Application.BACKOFFICE}>
      <AuthProvider>
        <InjectContextTenantIntoRedux>
          <StrictMode>
            <CheckKeycloakInstance>
              <GraphqlTenantHeadersSetup />
              <PrismicProvider client={client}>
                <ThemeProvider>
                  {/*@ts-ignore*/}
                  <HelmetProvider>
                    <FaviconSetter />
                    <MetaUpdate />
                    <CssBaseline />
                    <AppLoaders />
                    <GlobalStyles />
                    <InitI18n>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SettingsProvider>
                          <GlobalModalProvider>
                            <BrowserRouter>{children}</BrowserRouter>
                          </GlobalModalProvider>
                        </SettingsProvider>
                      </LocalizationProvider>
                    </InitI18n>
                  </HelmetProvider>
                </ThemeProvider>
              </PrismicProvider>
            </CheckKeycloakInstance>
          </StrictMode>
        </InjectContextTenantIntoRedux>
      </AuthProvider>
    </TenantProvider>
  );
};
