import { FC, useCallback, useState } from "react";
import { Badge, Box, Container, Grid, Tab, Theme, useTheme } from "@mui/material";
import useSettings from "../../hooks/useSettings";
import { useProperty } from "../../hooks/use-property";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PageTitle from "src/components/PageTitle";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { grey } from "@mui/material/colors";
import { useSelector } from "../../store";
import { pickerDateSelector } from "../../slices/date-picker";
import { makeStyles } from "tss-react/mui";
import { HousekeepingUnitsToClean } from "./housekeeping-units-to-clean";
import { HousekeepingAdditionalTasks } from "./housekeeping-additional-tasks";
import { HousekeepingLeftoverTasks } from "./housekeeping-leftover-tasks";
import { useGetHousekeepingCardsQueryEnhanced } from "../../graphql-tasks/query/enhanced-queries/get-housekeeping-cards-enhanced";
import { useGetHousekeepingTabCountsQueryEnhanced } from "src/graphql-tasks/query/enhanced-queries/get-housekeeping-tab-counts-enhanced";
import { ReloadButton } from "../../components/reload-button";
import { DirtyUnitsActions } from "../dirty-units/dirty-units-actions";
import { MaintenanceModal } from "../../components/maintenance-modal/maintenance-modal";
import { MaintenanceSlotArgs } from "../../store/endpoints/unit.endpoints";
import { useUnitBulkActions } from "../../hooks/use-unit-bulk-actions";
import { HousekeepingForecast } from "./housekeeping-forecast";
import { ExportActionType, HousekeepingForecastActions } from "./housekeeping-forecast-actions";
import { useLazyGetForecastFileQuery } from "../../store/task-management-files.api";
import { downloadFile } from "../../utils/file-utils";

enum TAB_VALUES {
  UNITS_TO_CLEAN = "1",
  ADDITIONAL_TASKS = "2",
  LEFTOVER_TASKS = "3",
  FORECAST = "4"
}

const useStyles = makeStyles()((theme: Theme) => ({
  tab: {
    paddingRight: theme.spacing(4),
    textTransform: "none",
    color: grey[600],
    fontWeight: "bold"
  },
  badge: {
    "& .MuiBadge-badge": { top: 9, right: -15 }
  }
}));

export const HousekeepingPage: FC = () => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();
  const { selectedProperty, selectedPropertiesPreview } = useProperty();
  const { settings } = useSettings();
  const { classes } = useStyles();
  const date = useSelector(pickerDateSelector);
  const { refetch: refetchHousekeepingCards } = useGetHousekeepingCardsQueryEnhanced({
    pmsPropertyId: selectedProperty?.propertyId ?? "",
    date
  });
  const { data: tabCounts, refetch: refetchTabCounts } = useGetHousekeepingTabCountsQueryEnhanced({
    pmsPropertyId: selectedProperty?.propertyId ?? ""
  });
  const [tabValue, setTabValue] = useState(TAB_VALUES.UNITS_TO_CLEAN);
  const { bulkAction, addMaintenance, maintenanceModalOpen, setMaintenanceModalOpen } =
    useUnitBulkActions();
  const [getForecastFile] = useLazyGetForecastFileQuery();

  const handleTabChange = (_: TAB_VALUES, newValue: TAB_VALUES) => {
    setTabValue(newValue);
  };

  const onHousekeepingForecastActionClick = async (actionType: ExportActionType) => {
    const response = await getForecastFile({
      propertyId: selectedProperty?.propertyId ?? "",
      exportType: actionType
    });
    downloadFile(response?.data?.data ?? "", response?.data?.fileName ?? "");
  };

  const handleReloadAction = useCallback(() => {
    refetchHousekeepingCards();
    refetchTabCounts();
  }, [refetchHousekeepingCards, refetchTabCounts]);

  return (
    <Box py={spacing(4)}>
      <Container maxWidth={settings.compact ? "xl" : false}>
        <Grid container spacing={spacing(3)}>
          <Grid container item alignItems="center" justifyContent="space-between">
            <Grid item>
              <PageTitle
                subTitle={selectedPropertiesPreview}
                title={t("labels__housekeeping")}
                beta
              />
            </Grid>
          </Grid>
          <TabContext value={tabValue}>
            <Grid container justifyContent="space-between" pl={spacing(3)}>
              <Grid item>
                <TabList
                  onChange={(event, newValue) =>
                    handleTabChange(event as unknown as TAB_VALUES, newValue)
                  }
                  variant="scrollable"
                >
                  <Tab
                    className={classes.tab}
                    value={TAB_VALUES.UNITS_TO_CLEAN}
                    label={
                      <Badge
                        className={classes.badge}
                        badgeContent={tabCounts?.GetHousekeepingTabCounts?.HOUSEKEEPING_CARDS}
                      >
                        {t("labels__units_to_clean")}
                      </Badge>
                    }
                  />
                  <Tab
                    className={classes.tab}
                    value={TAB_VALUES.ADDITIONAL_TASKS}
                    label={
                      <Badge
                        className={classes.badge}
                        badgeContent={tabCounts?.GetHousekeepingTabCounts?.ADDITIONAL_TASKS}
                      >
                        {t("labels__additional_tasks")}
                      </Badge>
                    }
                  />
                  <Tab
                    className={classes.tab}
                    value={TAB_VALUES.LEFTOVER_TASKS}
                    label={
                      <Badge
                        className={classes.badge}
                        badgeContent={tabCounts?.GetHousekeepingTabCounts?.LEFTOVER_TASKS}
                      >
                        {t("labels__leftover_tasks")}
                      </Badge>
                    }
                  />
                  <Tab
                    className={classes.tab}
                    value={TAB_VALUES.FORECAST}
                    label={<Badge className={classes.badge}>{t("labels__forecast")}</Badge>}
                  />
                </TabList>
              </Grid>
              {tabValue === TAB_VALUES.UNITS_TO_CLEAN && (
                <Grid item pl={spacing(1.5)} display="flex" alignItems="center" gap={spacing(2)}>
                  <DirtyUnitsActions onClick={bulkAction} />
                  <ReloadButton onClick={handleReloadAction} />
                </Grid>
              )}
              {tabValue === TAB_VALUES.FORECAST && (
                <Grid item pl={spacing(1.5)} display="flex" alignItems="center" gap={spacing(2)}>
                  <HousekeepingForecastActions onClick={onHousekeepingForecastActionClick} />
                  <ReloadButton onClick={handleReloadAction} />
                </Grid>
              )}
            </Grid>

            <TabPanel value={TAB_VALUES.UNITS_TO_CLEAN} sx={{ width: "100%", pr: 0 }}>
              <HousekeepingUnitsToClean selectedProperty={selectedProperty} />
            </TabPanel>
            <TabPanel value={TAB_VALUES.ADDITIONAL_TASKS} sx={{ width: "100%", pr: 0 }}>
              <HousekeepingAdditionalTasks propertyId={selectedProperty?.propertyId} />
            </TabPanel>
            <TabPanel value={TAB_VALUES.LEFTOVER_TASKS} sx={{ width: "100%", pr: 0 }}>
              <HousekeepingLeftoverTasks propertyId={selectedProperty?.propertyId} />
            </TabPanel>
            <TabPanel value={TAB_VALUES.FORECAST} sx={{ width: "100%", pr: 0 }}>
              <HousekeepingForecast />
            </TabPanel>
          </TabContext>
        </Grid>
      </Container>
      {maintenanceModalOpen && (
        <MaintenanceModal
          onSubmit={(values: MaintenanceSlotArgs) => addMaintenance(values)}
          onClose={() => setMaintenanceModalOpen(false)}
        />
      )}
    </Box>
  );
};
