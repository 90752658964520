import React, { FC, PropsWithChildren, ReactElement, useState } from "react";
import { Box, FormControl, MenuItem, MenuList, Paper, Popover } from "@mui/material";
import ChevronDownIcon from "@mui/icons-material/ExpandMore";
import ChevronUpIcon from "@mui/icons-material/ExpandLess";
import { generateUUID } from "../../utils/data.utils";
import { Button } from "@likemagic-tech/sv-magic-library";

interface ActionDropdownProps {
  handleOption: (optionId: string) => void;
  options: {
    label: string | ReactElement;
    id: string;
  }[];
}

export const ActionDropdown: FC<PropsWithChildren<ActionDropdownProps>> = ({
  options,
  handleOption,
  children
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <FormControl variant="outlined">
        <Button
          onClick={handleClick}
          color="info"
          variant="ghost"
          endIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        >
          {children}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Paper
            sx={{
              overflowY: "auto",
              minWidth: 120
            }}
          >
            <MenuList id={`split-button-menu-${generateUUID()}`}>
              {options.map(({ id, label }) => (
                <MenuItem
                  key={`action-dropdown-${id}`}
                  onClick={() => {
                    setOpen(false);
                    handleOption(id);
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popover>
      </FormControl>
    </Box>
  );
};

export default ActionDropdown;
