import { useBackofficeGetUpsellOffersQueryEnhanced } from "../../../../../../graphql/queries/enhanced-queries/get-upsell-offers";
import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { mapQueryStatusToEntityStateStatus } from "../../../../../../graphql/transform/transform-utils";

export const useUpsellUnitGroups = ({ reservationId }: { reservationId?: string }) => {
  const { features } = usePropertyConfig({});

  const { data, status } = useBackofficeGetUpsellOffersQueryEnhanced(
    { pmsReservationId: reservationId ?? "" },
    {
      skip: !features?.upsellUnitGroupEnabled || !reservationId
    }
  );

  return {
    items: data?.BackofficeGetUpsellOffers ?? [],
    status: mapQueryStatusToEntityStateStatus(status)
  };
};
