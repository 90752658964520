export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Instant: { input: any; output: any };
  JSON: { input: any; output: any };
  LocalDate: { input: any; output: any };
  Period: { input: any; output: any };
};

export type Actor = {
  __typename?: "Actor";
  active: Scalars["Boolean"]["output"];
  actorId?: Maybe<Scalars["ID"]["output"]>;
  actorType: ActorType;
  assignable: Scalars["Boolean"]["output"];
  displayName?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  keycloakUUID?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type ActorGroup = {
  __typename?: "ActorGroup";
  employees?: Maybe<Array<Employee>>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  type: ActorGroupType;
};

export enum ActorGroupType {
  EmployeeGroup = "EMPLOYEE_GROUP",
  System = "SYSTEM"
}

export enum ActorType {
  ActorGroup = "ACTOR_GROUP",
  Employee = "EMPLOYEE"
}

export type Attachment = {
  __typename?: "Attachment";
  contentType: Scalars["String"]["output"];
  fileName: Scalars["String"]["output"];
  originalFileName: Scalars["String"]["output"];
  uuid: Scalars["ID"]["output"];
};

export type CreateTaskRequest = {
  assigneeGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  assigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  attachments?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  due?: InputMaybe<Scalars["Instant"]["input"]>;
  links?: InputMaybe<LinksInput>;
  parentId?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Priority>;
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
};

export type Employee = {
  __typename?: "Employee";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  keycloakUUID?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type ForecastOutput = {
  __typename?: "ForecastOutput";
  date: Scalars["LocalDate"]["output"];
  departureCleaningCount: Scalars["Int"]["output"];
  /**  Map from task title (string) to count (int) */
  serviceTaskCounts: Scalars["JSON"]["output"];
  stayoverCleaningCount: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type HousekeepingCards = {
  __typename?: "HousekeepingCards";
  /**  always empty when date = today */
  reservations: Array<Reservation>;
  units: Array<Unit>;
};

export type Links = {
  __typename?: "Links";
  arrivingReservation?: Maybe<Reservation>;
  departingReservation?: Maybe<Reservation>;
  pmsArrivingReservationId?: Maybe<Scalars["String"]["output"]>;
  pmsDepartingReservationId?: Maybe<Scalars["String"]["output"]>;
  /**  exposed as well like this so FE doesn't have to recalculate the list */
  pmsReservationIds?: Maybe<Array<Scalars["String"]["output"]>>;
  pmsUnitIds?: Maybe<Array<Scalars["String"]["output"]>>;
  /**  for FE to extract the relevant information */
  reservations?: Maybe<Array<Reservation>>;
  units?: Maybe<Array<Unit>>;
};

export type LinksInput = {
  pmsArrivingReservationId?: InputMaybe<Scalars["String"]["input"]>;
  pmsDepartingReservationId?: InputMaybe<Scalars["String"]["input"]>;
  pmsReservationIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pmsUnitIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MaintenanceSlot = {
  __typename?: "MaintenanceSlot";
  description?: Maybe<Scalars["String"]["output"]>;
  from: Scalars["Instant"]["output"];
  id: Scalars["String"]["output"];
  status: MaintenanceSlotStatus;
  to: Scalars["Instant"]["output"];
  type: MaintenanceSlotType;
};

export enum MaintenanceSlotStatus {
  Expired = "EXPIRED",
  Future = "FUTURE",
  Ongoing = "ONGOING"
}

export enum MaintenanceSlotType {
  OutOfInventory = "OUT_OF_INVENTORY",
  OutOfOrder = "OUT_OF_ORDER",
  OutOfService = "OUT_OF_SERVICE"
}

export type Mutation = {
  __typename?: "Mutation";
  CreateTask: TaskDto;
  /**  can only unlink attachments, cannot add them. Use rest endpoint for that */
  UpdateTask: TaskDto;
};

export type MutationCreateTaskArgs = {
  pmsPropertyId: Scalars["String"]["input"];
  request: CreateTaskRequest;
};

export type MutationUpdateTaskArgs = {
  pmsPropertyId: Scalars["String"]["input"];
  request: UpdateTaskRequest;
  taskId: Scalars["Int"]["input"];
};

export type Pageable = {
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Priority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
  Urgent = "URGENT"
}

export type Query = {
  __typename?: "Query";
  Forecast: Array<ForecastOutput>;
  GetActors: Array<Actor>;
  GetAdditionalTasks: Array<TaskDto>;
  GetGroups: Array<ActorGroup>;
  GetHousekeepingCards: HousekeepingCards;
  GetHousekeepingTabCounts: Scalars["JSON"]["output"];
  GetLeftoverTasks: Array<TaskDto>;
  GetQuickFilterCounts: Scalars["JSON"]["output"];
  GetRelatedToTasks: Array<TaskDto>;
  GetTask: TaskDto;
  GetTasks: TaskTable;
};

export type QueryForecastArgs = {
  period?: InputMaybe<Scalars["Period"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetAdditionalTasksArgs = {
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetHousekeepingCardsArgs = {
  date?: InputMaybe<Scalars["LocalDate"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetHousekeepingTabCountsArgs = {
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetLeftoverTasksArgs = {
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetQuickFilterCountsArgs = {
  filters?: InputMaybe<Scalars["JSON"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetRelatedToTasksArgs = {
  pmsPropertyId: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
};

export type QueryGetTaskArgs = {
  pmsPropertyId: Scalars["String"]["input"];
  taskId: Scalars["Int"]["input"];
};

export type QueryGetTasksArgs = {
  filters?: InputMaybe<Scalars["JSON"]["input"]>;
  pageable: Pageable;
  pmsPropertyId: Scalars["String"]["input"];
};

export type Reservation = {
  __typename?: "Reservation";
  /**  LM-2443 make not-nullable */
  adultsAmount?: Maybe<Scalars["Int"]["output"]>;
  arrival: Scalars["Instant"]["output"];
  childrenAges?: Maybe<Array<Scalars["Int"]["output"]>>;
  /**  LM-2443 make not-nullable */
  childrenAmount?: Maybe<Scalars["Int"]["output"]>;
  departure: Scalars["Instant"]["output"];
  displayableReservationId: Scalars["String"]["output"];
  housekeepingTasks?: Maybe<Array<TaskDto>>;
  pmsPropertyId: Scalars["String"]["output"];
  pmsReservationId: Scalars["String"]["output"];
  pmsUnitGroupId: Scalars["String"]["output"];
  pmsUnitId?: Maybe<Scalars["String"]["output"]>;
  /**  LM-2443 make not-nullable */
  status?: Maybe<ReservationStatus>;
};

export type ReservationHousekeepingTasksArgs = {
  date?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export enum ReservationStatus {
  Cancelled = "CANCELLED",
  CheckedOut = "CHECKED_OUT",
  Confirmed = "CONFIRMED",
  InHouse = "IN_HOUSE",
  NoShow = "NO_SHOW"
}

export enum Status {
  Done = "DONE",
  InProgress = "IN_PROGRESS",
  Todo = "TODO"
}

export type Subscription = {
  __typename?: "Subscription";
  StreamTask?: Maybe<TaskDto>;
};

export type SubscriptionStreamTaskArgs = {
  pmsPropertyId: Scalars["String"]["input"];
};

export type TaskDto = {
  __typename?: "TaskDto";
  assignee?: Maybe<Actor>;
  assigneeGroup?: Maybe<Actor>;
  attachments?: Maybe<Array<Attachment>>;
  createdAt?: Maybe<Scalars["Instant"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  due?: Maybe<Scalars["Instant"]["output"]>;
  id: Scalars["Int"]["output"];
  isPendingUrgentTask: Scalars["Boolean"]["output"];
  links?: Maybe<Links>;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  parent?: Maybe<TaskDto>;
  parentId?: Maybe<Scalars["Int"]["output"]>;
  priority: Priority;
  reporter: Actor;
  status: Status;
  subtasks?: Maybe<Array<TaskDto>>;
  title: Scalars["String"]["output"];
  type: TaskType;
  updatedAt?: Maybe<Scalars["Instant"]["output"]>;
};

export type TaskTable = {
  __typename?: "TaskTable";
  content: Array<TaskDto>;
  totalElements: Scalars["Int"]["output"];
};

export enum TaskType {
  AutomatedTask = "AUTOMATED_TASK",
  DepartureCleaning = "DEPARTURE_CLEANING",
  GuestTask = "GUEST_TASK",
  ManualTask = "MANUAL_TASK",
  ServiceTask = "SERVICE_TASK",
  StayoverCleaning = "STAYOVER_CLEANING"
}

export type Unit = {
  __typename?: "Unit";
  floor?: Maybe<Scalars["String"]["output"]>;
  housekeepingTasks?: Maybe<Array<TaskDto>>;
  maintenanceSlots?: Maybe<Array<MaintenanceSlot>>;
  name: Scalars["String"]["output"];
  /**  LM-2443 make not-nullable */
  occupied: Scalars["Boolean"]["output"];
  pmsPropertyId: Scalars["String"]["output"];
  pmsUnitGroupId?: Maybe<Scalars["String"]["output"]>;
  /**  LM-2443 make not-nullable */
  pmsUnitId: Scalars["String"]["output"];
  /**  LM-2443 make not-nullable */
  state?: Maybe<UnitState>;
  unitGroupName?: Maybe<Scalars["String"]["output"]>;
};

export type UnitHousekeepingTasksArgs = {
  date?: InputMaybe<Scalars["LocalDate"]["input"]>;
};

export enum UnitState {
  Clean = "CLEAN",
  CleanToBeInspected = "CLEAN_TO_BE_INSPECTED",
  Dirty = "DIRTY",
  OutOfOrder = "OUT_OF_ORDER",
  OutOfService = "OUT_OF_SERVICE",
  Pickup = "PICKUP"
}

/**  the only difference is that a few more fields are mandatory. ReporterId is not present */
export type UpdateTaskRequest = {
  assigneeGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  assigneeId?: InputMaybe<Scalars["ID"]["input"]>;
  attachments?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  due?: InputMaybe<Scalars["Instant"]["input"]>;
  links?: InputMaybe<LinksInput>;
  parentId?: InputMaybe<Scalars["Int"]["input"]>;
  priority: Priority;
  status: Status;
  title: Scalars["String"]["input"];
};

export type ActorFragment = {
  __typename?: "Actor";
  actorId?: string | null;
  actorType: ActorType;
  displayName?: string | null;
};

export type HousekeepingTaskFragment = {
  __typename?: "TaskDto";
  type: TaskType;
  id: number;
  title: string;
  description?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  due?: any | null;
  status: Status;
  priority: Priority;
  parentId?: number | null;
  attachments?: Array<{
    __typename?: "Attachment";
    uuid: string;
    contentType: string;
    fileName: string;
    originalFileName: string;
  }> | null;
  assignee?: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: ActorType;
    displayName?: string | null;
  } | null;
  assigneeGroup?: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: ActorType;
    displayName?: string | null;
  } | null;
  reporter: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: ActorType;
    displayName?: string | null;
  };
  links?: {
    __typename?: "Links";
    pmsReservationIds?: Array<string> | null;
    pmsDepartingReservationId?: string | null;
    pmsArrivingReservationId?: string | null;
    pmsUnitIds?: Array<string> | null;
    reservations?: Array<{
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
    }> | null;
    departingReservation?: {
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
      departure: any;
    } | null;
    arrivingReservation?: {
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
    } | null;
    units?: Array<{
      __typename?: "Unit";
      pmsUnitId: string;
      name: string;
      floor?: string | null;
    }> | null;
  } | null;
  subtasks?: Array<{
    __typename?: "TaskDto";
    id: number;
    title: string;
    parentId?: number | null;
  }> | null;
  parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
};

export type ReservationFragment = {
  __typename?: "Reservation";
  adultsAmount?: number | null;
  arrival: any;
  childrenAges?: Array<number> | null;
  childrenAmount?: number | null;
  departure: any;
  displayableReservationId: string;
  pmsPropertyId: string;
  pmsReservationId: string;
  pmsUnitGroupId: string;
  pmsUnitId?: string | null;
  status?: ReservationStatus | null;
};

export type TaskFragment = {
  __typename?: "TaskDto";
  id: number;
  title: string;
  description?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  due?: any | null;
  status: Status;
  priority: Priority;
  parentId?: number | null;
  attachments?: Array<{
    __typename?: "Attachment";
    uuid: string;
    contentType: string;
    fileName: string;
    originalFileName: string;
  }> | null;
  assignee?: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: ActorType;
    displayName?: string | null;
  } | null;
  assigneeGroup?: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: ActorType;
    displayName?: string | null;
  } | null;
  reporter: {
    __typename?: "Actor";
    actorId?: string | null;
    actorType: ActorType;
    displayName?: string | null;
  };
  links?: {
    __typename?: "Links";
    pmsReservationIds?: Array<string> | null;
    pmsDepartingReservationId?: string | null;
    pmsArrivingReservationId?: string | null;
    pmsUnitIds?: Array<string> | null;
    reservations?: Array<{
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
    }> | null;
    departingReservation?: {
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
      departure: any;
    } | null;
    arrivingReservation?: {
      __typename?: "Reservation";
      displayableReservationId: string;
      pmsReservationId: string;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
    } | null;
    units?: Array<{
      __typename?: "Unit";
      pmsUnitId: string;
      name: string;
      floor?: string | null;
    }> | null;
  } | null;
  subtasks?: Array<{
    __typename?: "TaskDto";
    id: number;
    title: string;
    parentId?: number | null;
  }> | null;
  parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
};

export type CreateTaskMutationVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  request: CreateTaskRequest;
}>;

export type CreateTaskMutation = {
  __typename?: "Mutation";
  CreateTask: { __typename?: "TaskDto"; id: number };
};

export type UpdateTaskMutationVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  request: UpdateTaskRequest;
  taskId: Scalars["Int"]["input"];
}>;

export type UpdateTaskMutation = {
  __typename?: "Mutation";
  UpdateTask: { __typename?: "TaskDto"; id: number };
};

export type ForecastQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type ForecastQuery = {
  __typename?: "Query";
  Forecast: Array<{
    __typename?: "ForecastOutput";
    date: any;
    departureCleaningCount: number;
    stayoverCleaningCount: number;
    serviceTaskCounts: any;
    total: number;
  }>;
};

export type GetActorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActorsQuery = {
  __typename?: "Query";
  GetActors: Array<{
    __typename?: "Actor";
    actorId?: string | null;
    actorType: ActorType;
    displayName?: string | null;
    assignable: boolean;
    keycloakUUID?: string | null;
  }>;
};

export type GetAdditionalTasksQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetAdditionalTasksQuery = {
  __typename?: "Query";
  GetAdditionalTasks: Array<{
    __typename?: "TaskDto";
    type: TaskType;
    id: number;
    title: string;
    description?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    due?: any | null;
    status: Status;
    priority: Priority;
    parentId?: number | null;
    attachments?: Array<{
      __typename?: "Attachment";
      uuid: string;
      contentType: string;
      fileName: string;
      originalFileName: string;
    }> | null;
    assignee?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    } | null;
    assigneeGroup?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    } | null;
    reporter: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    };
    links?: {
      __typename?: "Links";
      pmsReservationIds?: Array<string> | null;
      pmsDepartingReservationId?: string | null;
      pmsArrivingReservationId?: string | null;
      pmsUnitIds?: Array<string> | null;
      reservations?: Array<{
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
      }> | null;
      departingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        departure: any;
      } | null;
      arrivingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
      } | null;
      units?: Array<{
        __typename?: "Unit";
        pmsUnitId: string;
        name: string;
        floor?: string | null;
      }> | null;
    } | null;
    subtasks?: Array<{
      __typename?: "TaskDto";
      id: number;
      title: string;
      parentId?: number | null;
    }> | null;
    parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
  }>;
};

export type GetHousekeepingCardsQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["LocalDate"]["input"]>;
}>;

export type GetHousekeepingCardsQuery = {
  __typename?: "Query";
  GetHousekeepingCards: {
    __typename?: "HousekeepingCards";
    reservations: Array<{
      __typename?: "Reservation";
      adultsAmount?: number | null;
      arrival: any;
      childrenAges?: Array<number> | null;
      childrenAmount?: number | null;
      departure: any;
      displayableReservationId: string;
      pmsPropertyId: string;
      pmsReservationId: string;
      pmsUnitGroupId: string;
      pmsUnitId?: string | null;
      status?: ReservationStatus | null;
      housekeepingTasks?: Array<{
        __typename?: "TaskDto";
        type: TaskType;
        id: number;
        title: string;
        description?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        due?: any | null;
        status: Status;
        priority: Priority;
        parentId?: number | null;
        attachments?: Array<{
          __typename?: "Attachment";
          uuid: string;
          contentType: string;
          fileName: string;
          originalFileName: string;
        }> | null;
        assignee?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        } | null;
        assigneeGroup?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        } | null;
        reporter: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        };
        links?: {
          __typename?: "Links";
          pmsReservationIds?: Array<string> | null;
          pmsDepartingReservationId?: string | null;
          pmsArrivingReservationId?: string | null;
          pmsUnitIds?: Array<string> | null;
          reservations?: Array<{
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          }> | null;
          departingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
            departure: any;
          } | null;
          arrivingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          } | null;
          units?: Array<{
            __typename?: "Unit";
            pmsUnitId: string;
            name: string;
            floor?: string | null;
          }> | null;
        } | null;
        subtasks?: Array<{
          __typename?: "TaskDto";
          id: number;
          title: string;
          parentId?: number | null;
        }> | null;
        parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
      }> | null;
    }>;
    units: Array<{
      __typename?: "Unit";
      floor?: string | null;
      name: string;
      occupied: boolean;
      pmsPropertyId: string;
      pmsUnitGroupId?: string | null;
      pmsUnitId: string;
      state?: UnitState | null;
      unitGroupName?: string | null;
      housekeepingTasks?: Array<{
        __typename?: "TaskDto";
        type: TaskType;
        id: number;
        title: string;
        description?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        due?: any | null;
        status: Status;
        priority: Priority;
        parentId?: number | null;
        attachments?: Array<{
          __typename?: "Attachment";
          uuid: string;
          contentType: string;
          fileName: string;
          originalFileName: string;
        }> | null;
        assignee?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        } | null;
        assigneeGroup?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        } | null;
        reporter: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        };
        links?: {
          __typename?: "Links";
          pmsReservationIds?: Array<string> | null;
          pmsDepartingReservationId?: string | null;
          pmsArrivingReservationId?: string | null;
          pmsUnitIds?: Array<string> | null;
          reservations?: Array<{
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          }> | null;
          departingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
            departure: any;
          } | null;
          arrivingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          } | null;
          units?: Array<{
            __typename?: "Unit";
            pmsUnitId: string;
            name: string;
            floor?: string | null;
          }> | null;
        } | null;
        subtasks?: Array<{
          __typename?: "TaskDto";
          id: number;
          title: string;
          parentId?: number | null;
        }> | null;
        parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
      }> | null;
      maintenanceSlots?: Array<{
        __typename?: "MaintenanceSlot";
        id: string;
        description?: string | null;
        from: any;
        status: MaintenanceSlotStatus;
        to: any;
        type: MaintenanceSlotType;
      }> | null;
    }>;
  };
};

export type GetHousekeepingTabCountsQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetHousekeepingTabCountsQuery = { __typename?: "Query"; GetHousekeepingTabCounts: any };

export type GetLeftoverTasksQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetLeftoverTasksQuery = {
  __typename?: "Query";
  GetLeftoverTasks: Array<{
    __typename?: "TaskDto";
    type: TaskType;
    id: number;
    title: string;
    description?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    due?: any | null;
    status: Status;
    priority: Priority;
    parentId?: number | null;
    attachments?: Array<{
      __typename?: "Attachment";
      uuid: string;
      contentType: string;
      fileName: string;
      originalFileName: string;
    }> | null;
    assignee?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    } | null;
    assigneeGroup?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    } | null;
    reporter: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    };
    links?: {
      __typename?: "Links";
      pmsReservationIds?: Array<string> | null;
      pmsDepartingReservationId?: string | null;
      pmsArrivingReservationId?: string | null;
      pmsUnitIds?: Array<string> | null;
      reservations?: Array<{
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
      }> | null;
      departingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        departure: any;
      } | null;
      arrivingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
      } | null;
      units?: Array<{
        __typename?: "Unit";
        pmsUnitId: string;
        name: string;
        floor?: string | null;
      }> | null;
    } | null;
    subtasks?: Array<{
      __typename?: "TaskDto";
      id: number;
      title: string;
      parentId?: number | null;
    }> | null;
    parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
  }>;
};

export type GetQuickFilterCountsQueryVariables = Exact<{
  filters?: InputMaybe<Scalars["JSON"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetQuickFilterCountsQuery = { __typename?: "Query"; GetQuickFilterCounts: any };

export type GetRelatedToQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
}>;

export type GetRelatedToQuery = {
  __typename?: "Query";
  GetRelatedToTasks: Array<{
    __typename?: "TaskDto";
    id: number;
    title: string;
    description?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    type: TaskType;
    due?: any | null;
    status: Status;
    priority: Priority;
    parentId?: number | null;
    subtasks?: Array<{
      __typename?: "TaskDto";
      id: number;
      title: string;
      parentId?: number | null;
      description?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      type: TaskType;
      due?: any | null;
      status: Status;
      priority: Priority;
      attachments?: Array<{
        __typename?: "Attachment";
        uuid: string;
        contentType: string;
        fileName: string;
        originalFileName: string;
      }> | null;
      assignee?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      } | null;
      assigneeGroup?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      } | null;
      reporter: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      };
      links?: {
        __typename?: "Links";
        pmsReservationIds?: Array<string> | null;
        pmsDepartingReservationId?: string | null;
        pmsArrivingReservationId?: string | null;
        pmsUnitIds?: Array<string> | null;
        reservations?: Array<{
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
        }> | null;
        departingReservation?: {
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
          departure: any;
        } | null;
        arrivingReservation?: {
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
        } | null;
        units?: Array<{
          __typename?: "Unit";
          pmsUnitId: string;
          name: string;
          floor?: string | null;
        }> | null;
      } | null;
      subtasks?: Array<{
        __typename?: "TaskDto";
        id: number;
        title: string;
        parentId?: number | null;
      }> | null;
      parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
    }> | null;
    attachments?: Array<{
      __typename?: "Attachment";
      uuid: string;
      contentType: string;
      fileName: string;
      originalFileName: string;
    }> | null;
    assignee?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    } | null;
    assigneeGroup?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    } | null;
    reporter: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    };
    links?: {
      __typename?: "Links";
      pmsReservationIds?: Array<string> | null;
      pmsDepartingReservationId?: string | null;
      pmsArrivingReservationId?: string | null;
      pmsUnitIds?: Array<string> | null;
      reservations?: Array<{
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
      }> | null;
      departingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        departure: any;
      } | null;
      arrivingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
      } | null;
      units?: Array<{
        __typename?: "Unit";
        pmsUnitId: string;
        name: string;
        floor?: string | null;
      }> | null;
    } | null;
    parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
  }>;
};

export type GetTaskQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  taskId: Scalars["Int"]["input"];
}>;

export type GetTaskQuery = {
  __typename?: "Query";
  GetTask: {
    __typename?: "TaskDto";
    id: number;
    title: string;
    description?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    type: TaskType;
    due?: any | null;
    status: Status;
    priority: Priority;
    parentId?: number | null;
    subtasks?: Array<{
      __typename?: "TaskDto";
      id: number;
      title: string;
      parentId?: number | null;
      description?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      type: TaskType;
      due?: any | null;
      status: Status;
      priority: Priority;
      attachments?: Array<{
        __typename?: "Attachment";
        uuid: string;
        contentType: string;
        fileName: string;
        originalFileName: string;
      }> | null;
      assignee?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      } | null;
      assigneeGroup?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      } | null;
      reporter: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      };
      links?: {
        __typename?: "Links";
        pmsReservationIds?: Array<string> | null;
        pmsDepartingReservationId?: string | null;
        pmsArrivingReservationId?: string | null;
        pmsUnitIds?: Array<string> | null;
        reservations?: Array<{
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
        }> | null;
        departingReservation?: {
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
          departure: any;
        } | null;
        arrivingReservation?: {
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
        } | null;
        units?: Array<{
          __typename?: "Unit";
          pmsUnitId: string;
          name: string;
          floor?: string | null;
        }> | null;
      } | null;
      subtasks?: Array<{
        __typename?: "TaskDto";
        id: number;
        title: string;
        parentId?: number | null;
      }> | null;
      parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
    }> | null;
    attachments?: Array<{
      __typename?: "Attachment";
      uuid: string;
      contentType: string;
      fileName: string;
      originalFileName: string;
    }> | null;
    assignee?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    } | null;
    assigneeGroup?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    } | null;
    reporter: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: ActorType;
      displayName?: string | null;
    };
    links?: {
      __typename?: "Links";
      pmsReservationIds?: Array<string> | null;
      pmsDepartingReservationId?: string | null;
      pmsArrivingReservationId?: string | null;
      pmsUnitIds?: Array<string> | null;
      reservations?: Array<{
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
      }> | null;
      departingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        departure: any;
      } | null;
      arrivingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
      } | null;
      units?: Array<{
        __typename?: "Unit";
        pmsUnitId: string;
        name: string;
        floor?: string | null;
      }> | null;
    } | null;
    parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
  };
};

export type GetTasksQueryVariables = Exact<{
  filters?: InputMaybe<Scalars["JSON"]["input"]>;
  pageable: Pageable;
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetTasksQuery = {
  __typename?: "Query";
  GetTasks: {
    __typename?: "TaskTable";
    totalElements: number;
    content: Array<{
      __typename?: "TaskDto";
      id: number;
      title: string;
      description?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      type: TaskType;
      due?: any | null;
      status: Status;
      priority: Priority;
      parentId?: number | null;
      subtasks?: Array<{
        __typename?: "TaskDto";
        id: number;
        title: string;
        parentId?: number | null;
        description?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: TaskType;
        due?: any | null;
        status: Status;
        priority: Priority;
        attachments?: Array<{
          __typename?: "Attachment";
          uuid: string;
          contentType: string;
          fileName: string;
          originalFileName: string;
        }> | null;
        assignee?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        } | null;
        assigneeGroup?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        } | null;
        reporter: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: ActorType;
          displayName?: string | null;
        };
        links?: {
          __typename?: "Links";
          pmsReservationIds?: Array<string> | null;
          pmsDepartingReservationId?: string | null;
          pmsArrivingReservationId?: string | null;
          pmsUnitIds?: Array<string> | null;
          reservations?: Array<{
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          }> | null;
          departingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
            departure: any;
          } | null;
          arrivingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          } | null;
          units?: Array<{
            __typename?: "Unit";
            pmsUnitId: string;
            name: string;
            floor?: string | null;
          }> | null;
        } | null;
        subtasks?: Array<{
          __typename?: "TaskDto";
          id: number;
          title: string;
          parentId?: number | null;
        }> | null;
        parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
      }> | null;
      attachments?: Array<{
        __typename?: "Attachment";
        uuid: string;
        contentType: string;
        fileName: string;
        originalFileName: string;
      }> | null;
      assignee?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      } | null;
      assigneeGroup?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      } | null;
      reporter: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: ActorType;
        displayName?: string | null;
      };
      links?: {
        __typename?: "Links";
        pmsReservationIds?: Array<string> | null;
        pmsDepartingReservationId?: string | null;
        pmsArrivingReservationId?: string | null;
        pmsUnitIds?: Array<string> | null;
        reservations?: Array<{
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
        }> | null;
        departingReservation?: {
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
          departure: any;
        } | null;
        arrivingReservation?: {
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
        } | null;
        units?: Array<{
          __typename?: "Unit";
          pmsUnitId: string;
          name: string;
          floor?: string | null;
        }> | null;
      } | null;
      parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
    }>;
  };
};
