import { FC, ReactNode, useCallback, useEffect } from "react";
import { Drawer, IconButton } from "@mui/material";
import { useDispatch } from "../../store";
import { responsiveVariables, useIsMobile } from "../../hooks/use-is-mobile";
import { setIsOpenSideNavigation } from "../../slices/dashboard-sidebar";
import { buttonGeneralStyle } from "../../config/theme/theme-override";
import { Close } from "@mui/icons-material";
import { minimumNavbarHeight } from "src/components/dashboard/DashboardNavbar";
import { useTheme } from "@mui/material/styles";

interface DrawerDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

let zIndex = 1200;

export const DrawerDetails: FC<DrawerDetailsProps> = ({ children, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { breakpoints, spacing } = useTheme();
  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isOpen || isMobile) {
      setOpenSideNavigation(false);
    }
  }, [setOpenSideNavigation, isMobile, isOpen]);

  useEffect(() => {
    if (isOpen) {
      zIndex++;
    }
  }, [isOpen]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          zIndex: zIndex,
          [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
            width: "100%"
          },
          [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
            width: "75%"
          },
          top: minimumNavbarHeight,
          height: `calc(100% - ${minimumNavbarHeight}px)`,
          bgcolor: "background.default",
          boxShadow: buttonGeneralStyle.boxShadow,
          "&:hover": { bgcolor: "background.default" }
        }
      }}
      hideBackdrop
      variant="persistent"
      open={isOpen}
      anchor="right"
    >
      <IconButton
        sx={{
          [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
            position: "absolute",
            top: spacing(2),
            right: spacing(2),
            zIndex: 101,
            "&:hover": { bgcolor: "background.default" }
          },
          [breakpoints.up(responsiveVariables.firstDesktopSize)]: {
            position: "fixed",
            top: spacing(8),
            marginLeft: -5,
            bgcolor: "background.default",
            boxShadow: buttonGeneralStyle.boxShadow,
            borderRadius: " 50% 0 0 50%",
            "&:hover": { bgcolor: "background.default" }
          }
        }}
        onClick={onClose}
      >
        <Close />
      </IconButton>

      {isOpen ? children : <> </>}
    </Drawer>
  );
};
