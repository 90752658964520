import React, { FC } from "react";
import {
  EmptyState,
  EmptyStateSize,
  Heading4,
  PaperButton,
  Paragraph,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { Box, Grid, IconButton } from "@mui/material";
import { ArrowDownwardOutlined } from "@mui/icons-material";
import { UpsellUnitGroupItem } from "./upsell-unit-group-item";
import Paper from "@mui/material/Paper";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { useUnitGroupById } from "../../../../../../hooks/use-unit-group-by-id";
import { ReservationDetailsDTO } from "../../../../../../store/endpoints/reservation-table.endpoints";
import { useUpsellUnitGroups } from "./use-upsell-unit-groups";
import { Notification } from "../../../../../../components/notification";

export interface UpsellUnitGroupComponentProps {
  pmsUpsellRequest?: UpsellRequest;
  setPmsUpsellRequest: (val?: UpsellRequest) => void;
  reservation: ReservationDetailsDTO;
  successfullyUpdated?: boolean;
}

export interface UpsellRequest {
  pmsReservationId: string;
  pmsUnitGroupId: string;
  pmsUpsellRuleId: string;
}

export const UpsellUnitGroupComponent: FC<UpsellUnitGroupComponentProps> = ({
  pmsUpsellRequest,
  setPmsUpsellRequest,
  reservation,
  successfullyUpdated
}) => {
  const { t } = useTranslationWrapper();
  const unitGroup = useUnitGroupById(reservation.propertyId, reservation.bookedUnitGroupId);
  const { items } = useUpsellUnitGroups({ reservationId: reservation.id });

  return (
    <Paper sx={{ mt: 5, p: 2, pt: 4 }} elevation={0}>
      <Heading4 mb={2}>{t("titles__upsell_title")}</Heading4>

      <Box width="100%">
        <Grid container spacing={1}>
          <Grid item ml={1} xs={12}>
            <ParagraphSmall color="textSecondary">
              {t("labels__upsell_booked_options")}
            </ParagraphSmall>
          </Grid>
          <Grid item xs={12} key={`current_payment_option`}>
            <PaperButton
              disabled={successfullyUpdated}
              selected={pmsUpsellRequest?.pmsUpsellRuleId === undefined}
              onClick={() => setPmsUpsellRequest(undefined)}
            >
              <Paragraph textAlign="left">{unitGroup.name}</Paragraph>
            </PaperButton>
          </Grid>

          <Grid item xs={12}>
            <Box my={2} textAlign="center" width="100%">
              <IconButton
                disableRipple
                disableFocusRipple
                sx={{ boxShadow: "0px 1.6px 9.6px 0px rgba(0, 0, 0, 0.16)" }}
              >
                <ArrowDownwardOutlined />
              </IconButton>
            </Box>
          </Grid>
          {items.length > 0 && !successfullyUpdated && (
            <Grid ml={1} item xs={11}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ParagraphSmall color="textSecondary">
                    {t("labels__upsell_upgrade_options")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={3}>
                  <ParagraphSmall color="textSecondary">
                    {t("labels__upsell_per_night_options")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={3}>
                  <ParagraphSmall color="textSecondary">
                    {t("labels__upsell_whole_stay_options")}
                  </ParagraphSmall>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!successfullyUpdated &&
            items.map((upsellItem) => (
              <Grid item xs={12} key={`payment_option_${upsellItem.pmsUpsellRuleId}`}>
                <PaperButton
                  disabled={successfullyUpdated}
                  selected={pmsUpsellRequest?.pmsUpsellRuleId === upsellItem.pmsUpsellRuleId}
                  onClick={() => {
                    if (upsellItem) {
                      setPmsUpsellRequest({
                        pmsReservationId: reservation.id,
                        pmsUnitGroupId: upsellItem.pmsUnitGroupId,
                        pmsUpsellRuleId: upsellItem.pmsUpsellRuleId
                      });
                    }
                  }}
                >
                  {upsellItem && reservation.propertyId && (
                    <UpsellUnitGroupItem
                      upsellUnitGroup={upsellItem}
                      propertyId={reservation.propertyId}
                    />
                  )}
                </PaperButton>
              </Grid>
            ))}
        </Grid>

        {successfullyUpdated && (
          <Notification type="success" title={t("labels__upsell_success_notification")} />
        )}

        {!successfullyUpdated && items.length === 0 && (
          <Box>
            <EmptyState title={t("labels__no_upsell_offers")} size={EmptyStateSize.LARGE} />
          </Box>
        )}
      </Box>
    </Paper>
  );
};
