import React, { FC, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useManualCheckinSteps } from "../../../../../hooks/use-manual-checkin-steps";
import { ManualWizardStepItem } from "../../../components/manual-wizard/manual-wizard-step-item";
import { ManualWizardStepItemNavigation } from "../../../components/manual-wizard/manual-wizard-step-item-navigation";
import { useManualCheckinId } from "../../use-manual-checkin-id";
import { AdditionalServiceWithPrice, Service } from "../../../../../domain/service";
import { ManualCheckinStepProps } from "../manual-checkin-step-domain";
import { AdditionalServicesAutocomplete } from "../../../components/services/additional-services-autocomplete";
import { ServicesWithCart } from "../../../reservation-details/details-tabs/reservation-details-tabs/services/services-with-cart";
import { useAddServicesToReservationMutationEnhanced } from "../../../../../graphql/mutations/enhanced-mutations/add-services-to-reservation-enhanced";
import { useUpsellUnitGroups } from "../../../reservation-details/details-tabs/reservation-details-tabs/upsell-unit-groups/use-upsell-unit-groups";
import { ManualCheckinStepsEnum } from "../../../domain/enums";

export const ManualCheckinStepServices: FC<ManualCheckinStepProps> = ({ reservationDetails }) => {
  const { t } = useTranslationWrapper();
  const { manualCheckinId } = useManualCheckinId();
  const { nextStep, previousStep } = useManualCheckinSteps({
    propertyId: reservationDetails?.propertyId
  });
  const [additionalServices, setAdditionalServices] = useState<AdditionalServiceWithPrice[]>([]);
  const [addServicesToReservation] = useAddServicesToReservationMutationEnhanced();
  const { items: upsellIOffers } = useUpsellUnitGroups({ reservationId: reservationDetails?.id });

  const skipPages = useMemo(
    () => (upsellIOffers.length ? undefined : ManualCheckinStepsEnum.UPSELL_UNIT_GROUP),
    [upsellIOffers.length]
  );

  const onNext = async () => {
    if (additionalServices.length > 0) {
      await addServicesToReservation({
        pmsReservationId: manualCheckinId ?? "",
        services: additionalServices.map((service) => ({
          dates: service.dates,
          pmsServiceId: service.serviceId,
          quantity: service.quantity
        }))
      });
    }

    nextStep();
  };

  const onRemove = (service: Service) => {
    setAdditionalServices((prev) => prev.filter((s) => s.serviceId !== service.serviceId));
  };

  if (!reservationDetails) {
    return <></>;
  }

  return (
    <>
      <Grid p={3} pr={0} container justifyContent="space-between" alignItems="center">
        <Grid item xs={3}>
          <Heading4>{t("labels__wizard_services")}</Heading4>
        </Grid>
        <Grid item xs={3}>
          <AdditionalServicesAutocomplete
            setAdditionalServices={setAdditionalServices}
            additionalServices={additionalServices}
            reservationDetails={reservationDetails}
          />
        </Grid>
      </Grid>
      <ManualWizardStepItem>
        <ServicesWithCart
          additionalServices={additionalServices}
          bookedServices={reservationDetails.services}
          onRemove={onRemove}
        />
      </ManualWizardStepItem>
      <ManualWizardStepItemNavigation
        onNextStep={onNext}
        onPreviousStep={() => previousStep(skipPages)}
      />
    </>
  );
};
