import React, { FC, useCallback, useState } from "react";
import { ReservationDetailsDTO } from "../../../../../../store/endpoints/reservation-table.endpoints";
import { Box, Button } from "@mui/material";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { openBanner } from "../../../../../../slices/banner.slice";
import { useDispatch } from "../../../../../../store";
import { useBackofficeUpsellReservationMutationEnhanced } from "../../../../../../graphql/mutations/enhanced-mutations/upsell.reservation-enhanced";
import { UpsellRequest, UpsellUnitGroupComponent } from "./upsell-unit-group-component";
import { isStatusSuccess } from "../../../../../../domain/EntityStateStatus";
import { mapQueryStatusToEntityStateStatus } from "../../../../../../graphql/transform/transform-utils";
import { Notification } from "../../../../../../components/notification";

interface UpsellUnitGroupsTabProps {
  reservation: ReservationDetailsDTO;
}

export const UpsellUnitGroupsTab: FC<UpsellUnitGroupsTabProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();

  const [pmsUpsellRequest, setPmsUpsellRequest] = useState<UpsellRequest | undefined>();
  const [upsellReservation, { status }] = useBackofficeUpsellReservationMutationEnhanced();

  const dispatch = useDispatch();
  const onSubmit = useCallback(() => {
    if (pmsUpsellRequest) {
      upsellReservation(pmsUpsellRequest)
        .unwrap()
        .then(() => {
          dispatch(
            openBanner({
              type: "success",
              title: t("labels__action_successfully_performed")
            })
          );
        });
    }
  }, [upsellReservation, pmsUpsellRequest, dispatch, t]);

  return (
    <>
      <UpsellUnitGroupComponent
        pmsUpsellRequest={pmsUpsellRequest}
        setPmsUpsellRequest={setPmsUpsellRequest}
        reservation={reservation}
        successfullyUpdated={isStatusSuccess(mapQueryStatusToEntityStateStatus(status))}
      />

      {reservation.originalPmsUnitGroupId && (
        <Notification title={t("labels__reservation_has_been_upgraded")} type="info" />
      )}

      {pmsUpsellRequest && (
        <Box mt={2} textAlign="right">
          <Button variant="primary" onClick={onSubmit}>
            {t("buttons__upgrade_unit_group")}
          </Button>
        </Box>
      )}
    </>
  );
};
