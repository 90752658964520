import * as Types from "../generated/graphql";

import { PersonFragmentFragmentDoc } from "../fragments/Person.generated";
import { graphqlApi } from "../graphql.api";

export type OverviewDetailsQueryVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
}>;

export type OverviewDetailsQuery = {
  __typename?: "Query";
  OverviewDetails?: {
    __typename?: "BackofficeReservationOverviewDetail";
    adultsCount: number;
    allowedActions: Array<Types.ReservationAction>;
    arrival: any;
    maxCompanions: number;
    bookedPmsUnitGroupId?: string | null;
    bookingChannel?: Types.BookingChannelFilterValue | null;
    bookingMagicLink: string;
    checkinTime?: any | null;
    checkoutTime?: any | null;
    estimatedArrivalTime?: any | null;
    estimatedDepartureTime?: any | null;
    checkoutOnAr?: boolean | null;
    childrenAges?: Array<number> | null;
    childrenCount: number;
    completed?: boolean | null;
    contractualCheckInTime?: any | null;
    createdAt: any;
    departure: any;
    idCheckStatus: Types.IdCheckStatus;
    lastConfirmedPage?: Types.GuestFlowCheckPoint | null;
    lastConfirmedCheckoutPage?: Types.GuestJourneyCheckoutFlowCheckpoint | null;
    magicId: string;
    magicLink: string;
    magicToken: string;
    pmsOriginalPropertyId: string;
    pmsPropertyId: string;
    pmsReservationId: string;
    primaryGuestSameAsBooker?: boolean | null;
    reservationCondition?: Types.ReservationCondition | null;
    reservationConditionReasons?: any | null;
    reservationIsPaid?: boolean | null;
    reservationStatus?: Types.ReservationStatus | null;
    unitCleanOnCheckin?: boolean | null;
    extraPmsSimpleProperties?: any | null;
    checkedInBy?: Types.ReservationCheckInOutAuthor | null;
    checkedOutBy?: Types.ReservationCheckInOutAuthor | null;
    canCheckout: boolean;
    folios: Array<{
      __typename?: "Folio";
      closed?: any | null;
      created?: any | null;
      debitorType: Types.DebitorType;
      metadata: any;
      number?: string | null;
      id?: number | null;
      origin?: Types.Origin | null;
      pmsCompanyId?: string | null;
      pmsCustomerId?: string | null;
      pmsId?: string | null;
      prepaymentType: Types.PrepaymentType;
      propertyId?: number | null;
      updated?: any | null;
      orderItems: Array<{
        __typename?: "OrderItem";
        name?: string | null;
        pmsServiceId?: string | null;
        prepaid?: boolean | null;
        pmsId: string;
        quantity?: number | null;
        created?: any | null;
        note?: string | null;
        subName?: string | null;
        reference?: string | null;
        price: {
          __typename?: "Price";
          currency?: string | null;
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
          netPrice?: any | null;
          netPriceInCents?: number | null;
          taxes?: Array<{
            __typename?: "Tax";
            amount?: any | null;
            amountInCents?: number | null;
            code?: string | null;
          } | null> | null;
        };
      }>;
      payments: Array<{
        __typename?: "Payment";
        pmsId: string;
        created?: any | null;
        refundable: boolean;
        refundStatus?: Types.RefundStatus | null;
        type: Types.PaymentType;
        price: {
          __typename?: "Price";
          currency?: string | null;
          grossPriceInCents?: number | null;
        };
      }>;
      totalAllowedPayment?: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPriceInCents?: number | null;
      } | null;
      pendingPayments: Array<{
        __typename?: "PendingPayment";
        id: string;
        created?: any | null;
        price: {
          __typename?: "Price";
          currency?: string | null;
          grossPriceInCents?: number | null;
        };
      }>;
    }>;
    groupedRateBreakdown?: {
      __typename?: "GroupedRateBreakdown";
      rateSpliceTypeTuples?: Array<{
        __typename?: "RateSpliceTypeEnumTuple";
        type?: Types.RateSpliceTypeEnumContract | null;
        pmsIdToRateSplicesTuples?: Array<{
          __typename?: "PmsIdToRateSplicesTuple";
          pmsId?: string | null;
          rateSplices?: Array<{
            __typename?: "RateSplice";
            displayName?: string | null;
            quantity?: number | null;
            type?: Types.RateSpliceTypeEnumContract | null;
            grossPrice?: {
              __typename?: "GrossPrice";
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              currency?: string | null;
            } | null;
          }> | null;
        }> | null;
      }> | null;
    } | null;
    accessibleDoors: Array<{
      __typename?: "BackofficeAccessibleDoor";
      id: string;
      isGeneral?: boolean | null;
      lockAccessibility?: Types.DoorLockAccessibility | null;
      pin?: string | null;
      privacyMode?: boolean | null;
      sortPriority?: number | null;
      title?: string | null;
    }>;
    keyCards?: Array<{
      __typename?: "BackofficeKeyCard";
      id: string;
      createdAt?: any | null;
      isCopy?: boolean | null;
      requestor?: {
        __typename?: "DoorAccessRequestor";
        principal?: string | null;
        role?: string | null;
        travelBuddyGuestId?: string | null;
      } | null;
    }> | null;
    balance?: {
      __typename?: "GrossPrice";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
    } | null;
    booker?: {
      __typename?: "Person";
      id: number;
      pmsId: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: Types.Gender | null;
      birthdate?: any | null;
      age?: number | null;
      isMinor?: boolean | null;
      preferredCommunicationChannel?: Types.CommunicationChannel | null;
      termsAndConditionsGeneral: boolean;
      termsAndConditionsOnline: boolean;
      termsAndConditionsMarketingConsent: boolean;
      termsAndConditionsMarketingConsentDoubleOptin: boolean;
      emergencyEvacuationHelpNeeded: boolean;
      enrolledInLoyaltyProgram: boolean;
      identificationDocumentNumber?: string | null;
      identificationDocumentType?: string | null;
      userProfileId?: number | null;
      preferredLanguage?: string | null;
      nationality?: string | null;
      vipCode?: string | null;
      publicTransportationTickets?: Array<{
        __typename?: "PublicTransportationTicket";
        code?: string | null;
        link?: string | null;
        name?: string | null;
        valid?: boolean | null;
        validUntil?: any | null;
        ownerMagicId?: string | null;
      } | null> | null;
      userProfile?: {
        __typename?: "UserProfile";
        isRecurring: boolean;
        userAccountUuid?: string | null;
        preferences: Array<{
          __typename?: "Preference";
          pmsId: string;
          pmsPreferenceCategoryId: string;
          name: string;
          description?: string | null;
          pmsPropertyId?: string | null;
        }>;
        notes: Array<{
          __typename?: "ProfileNote";
          pmsId: string;
          title?: string | null;
          text?: string | null;
          pmsPropertyId?: string | null;
          pmsProfileNoteCategoryId?: string | null;
          category?: {
            __typename?: "ProfileNoteCategory";
            pmsId: string;
            name?: string | null;
          } | null;
        }>;
      } | null;
      address?: {
        __typename?: "Address";
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        region?: string | null;
      } | null;
      membershipAccounts: Array<{
        __typename?: "MembershipAccount";
        membershipTypeCode?: string | null;
        membershipId?: string | null;
        membershipClass?: Types.MemberShipClassEnum | null;
        pmsId?: string | null;
        name?: string | null;
      }>;
    } | null;
    files: Array<{
      __typename?: "BackofficeMagicFileDescriptor";
      contentType: string;
      fileName: string;
      metaData?: any | null;
    }>;
    notes?: {
      __typename?: "BackofficeNote";
      previousVisits?: number | null;
      guestComment?: Array<string> | null;
      bookerComment?: Array<string> | null;
      bookingComment?: Array<string> | null;
      rateBreakdownComment?: Array<string> | null;
      beddingComment?: Array<string> | null;
      comment?: Array<string> | null;
    } | null;
    primaryGuest?: {
      __typename?: "Person";
      id: number;
      pmsId: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: Types.Gender | null;
      birthdate?: any | null;
      age?: number | null;
      isMinor?: boolean | null;
      preferredCommunicationChannel?: Types.CommunicationChannel | null;
      termsAndConditionsGeneral: boolean;
      termsAndConditionsOnline: boolean;
      termsAndConditionsMarketingConsent: boolean;
      termsAndConditionsMarketingConsentDoubleOptin: boolean;
      emergencyEvacuationHelpNeeded: boolean;
      enrolledInLoyaltyProgram: boolean;
      identificationDocumentNumber?: string | null;
      identificationDocumentType?: string | null;
      userProfileId?: number | null;
      preferredLanguage?: string | null;
      nationality?: string | null;
      vipCode?: string | null;
      publicTransportationTickets?: Array<{
        __typename?: "PublicTransportationTicket";
        code?: string | null;
        link?: string | null;
        name?: string | null;
        valid?: boolean | null;
        validUntil?: any | null;
        ownerMagicId?: string | null;
      } | null> | null;
      userProfile?: {
        __typename?: "UserProfile";
        isRecurring: boolean;
        userAccountUuid?: string | null;
        preferences: Array<{
          __typename?: "Preference";
          pmsId: string;
          pmsPreferenceCategoryId: string;
          name: string;
          description?: string | null;
          pmsPropertyId?: string | null;
        }>;
        notes: Array<{
          __typename?: "ProfileNote";
          pmsId: string;
          title?: string | null;
          text?: string | null;
          pmsPropertyId?: string | null;
          pmsProfileNoteCategoryId?: string | null;
          category?: {
            __typename?: "ProfileNoteCategory";
            pmsId: string;
            name?: string | null;
          } | null;
        }>;
      } | null;
      address?: {
        __typename?: "Address";
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        region?: string | null;
      } | null;
      membershipAccounts: Array<{
        __typename?: "MembershipAccount";
        membershipTypeCode?: string | null;
        membershipId?: string | null;
        membershipClass?: Types.MemberShipClassEnum | null;
        pmsId?: string | null;
        name?: string | null;
      }>;
    } | null;
    sentNotifications: Array<{
      __typename?: "BackofficeSentNotification";
      bookingId?: string | null;
      channel?: Types.CommunicationChannel | null;
      contentLink?: string | null;
      conversationId?: string | null;
      createdAt?: any | null;
      id?: string | null;
      language?: Types.CommunicationLanguage | null;
      magicId?: string | null;
      recipient?: string | null;
      reservationId?: string | null;
      status?: string | null;
      template?: Types.NotificationType | null;
      lastUpdateTimestamp?: any | null;
      travelBuddyId?: string | null;
    }>;
    services: Array<{
      __typename?: "BackofficeService";
      quantity?: number | null;
      name?: string | null;
      code?: Types.ServiceCodeEnum | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        netPriceInCents?: number | null;
        taxes?: Array<{
          __typename?: "Tax";
          amount?: any | null;
          amountInCents?: number | null;
          code?: string | null;
        } | null> | null;
      } | null;
    }>;
    travelBuddies: Array<{
      __typename?: "Person";
      id: number;
      pmsId: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: Types.Gender | null;
      birthdate?: any | null;
      age?: number | null;
      isMinor?: boolean | null;
      preferredCommunicationChannel?: Types.CommunicationChannel | null;
      termsAndConditionsGeneral: boolean;
      termsAndConditionsOnline: boolean;
      termsAndConditionsMarketingConsent: boolean;
      termsAndConditionsMarketingConsentDoubleOptin: boolean;
      emergencyEvacuationHelpNeeded: boolean;
      enrolledInLoyaltyProgram: boolean;
      identificationDocumentNumber?: string | null;
      identificationDocumentType?: string | null;
      userProfileId?: number | null;
      preferredLanguage?: string | null;
      nationality?: string | null;
      vipCode?: string | null;
      publicTransportationTickets?: Array<{
        __typename?: "PublicTransportationTicket";
        code?: string | null;
        link?: string | null;
        name?: string | null;
        valid?: boolean | null;
        validUntil?: any | null;
        ownerMagicId?: string | null;
      } | null> | null;
      userProfile?: {
        __typename?: "UserProfile";
        isRecurring: boolean;
        userAccountUuid?: string | null;
        preferences: Array<{
          __typename?: "Preference";
          pmsId: string;
          pmsPreferenceCategoryId: string;
          name: string;
          description?: string | null;
          pmsPropertyId?: string | null;
        }>;
        notes: Array<{
          __typename?: "ProfileNote";
          pmsId: string;
          title?: string | null;
          text?: string | null;
          pmsPropertyId?: string | null;
          pmsProfileNoteCategoryId?: string | null;
          category?: {
            __typename?: "ProfileNoteCategory";
            pmsId: string;
            name?: string | null;
          } | null;
        }>;
      } | null;
      address?: {
        __typename?: "Address";
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        region?: string | null;
      } | null;
      membershipAccounts: Array<{
        __typename?: "MembershipAccount";
        membershipTypeCode?: string | null;
        membershipId?: string | null;
        membershipClass?: Types.MemberShipClassEnum | null;
        pmsId?: string | null;
        name?: string | null;
      }>;
    }>;
    unit?: {
      __typename?: "BackofficeUnit";
      pmsUnitId?: string | null;
      pmsUnitGroupId?: string | null;
      name?: string | null;
      privacyMode?: boolean | null;
      privacyModeLastChangeTimestamp?: any | null;
      condition?: Types.UnitState | null;
    } | null;
    restaurantReservations?: Array<{
      __typename?: "RestaurantReservation";
      foodAndBeverageReservationId: string;
      foodAndBeveragePropertyId: string;
      foodAndBeveragePropertyName: string;
      reservationDateTime: any;
      status: Types.RestaurantReservationStatus;
      notes?: string | null;
      expectedNumberOfGuests?: number | null;
      actualNumberOfGuests?: number | null;
      tableNumbers?: Array<string | null> | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    reservationUpsellInformation?: {
      __typename?: "ReservationUpsellInformation";
      originalPmsUnitGroupId?: string | null;
    } | null;
  } | null;
};

export const OverviewDetailsDocument = `
    query OverviewDetails($pmsReservationId: String!) {
  OverviewDetails(pmsReservationId: $pmsReservationId) {
    folios {
      closed
      created
      debitorType
      metadata
      orderItems {
        name
        pmsServiceId
        prepaid
        pmsId
        price {
          currency
          grossPrice
          grossPriceInCents
          netPrice
          netPriceInCents
          taxes {
            amount
            amountInCents
            code
          }
        }
        quantity
        created
        note
        subName
        reference
      }
      number
      id
      origin
      payments {
        pmsId
        created
        price {
          currency
          grossPriceInCents
        }
        refundable
        refundStatus
        type
      }
      pmsCompanyId
      pmsCustomerId
      pmsId
      prepaymentType
      propertyId
      totalAllowedPayment {
        currency
        grossPriceInCents
      }
      updated
      pendingPayments {
        id
        price {
          currency
          grossPriceInCents
        }
        created
      }
    }
    groupedRateBreakdown {
      rateSpliceTypeTuples {
        type
        pmsIdToRateSplicesTuples {
          pmsId
          rateSplices {
            grossPrice {
              grossPrice
              grossPriceInCents
              currency
            }
            displayName
            quantity
            type
          }
        }
      }
    }
    accessibleDoors {
      id
      isGeneral
      lockAccessibility
      pin
      privacyMode
      sortPriority
      title
    }
    keyCards {
      id
      createdAt
      isCopy
      requestor {
        principal
        role
        travelBuddyGuestId
      }
    }
    adultsCount
    allowedActions
    arrival
    maxCompanions
    balance {
      currency
      grossPrice
      grossPriceInCents
    }
    bookedPmsUnitGroupId
    booker {
      ...PersonFragment
    }
    bookingChannel
    bookingMagicLink
    checkinTime
    checkoutTime
    estimatedArrivalTime
    estimatedDepartureTime
    checkoutOnAr
    childrenAges
    childrenCount
    completed
    contractualCheckInTime
    createdAt
    departure
    files {
      contentType
      fileName
      metaData
    }
    idCheckStatus
    lastConfirmedPage
    lastConfirmedCheckoutPage
    magicId
    magicLink
    magicToken
    notes {
      previousVisits
      guestComment
      bookerComment
      bookingComment
      rateBreakdownComment
      beddingComment
      comment
    }
    pmsOriginalPropertyId
    pmsPropertyId
    pmsReservationId
    primaryGuest {
      ...PersonFragment
    }
    primaryGuestSameAsBooker
    reservationCondition
    reservationConditionReasons
    reservationIsPaid
    reservationStatus
    sentNotifications {
      bookingId
      channel
      contentLink
      conversationId
      createdAt
      id
      language
      magicId
      recipient
      reservationId
      status
      template
      lastUpdateTimestamp
      travelBuddyId
    }
    services {
      price {
        currency
        grossPrice
        grossPriceInCents
        taxes {
          amount
          amountInCents
          code
        }
        netPrice
        netPriceInCents
      }
      quantity
      name
      code
    }
    travelBuddies {
      ...PersonFragment
    }
    unit {
      pmsUnitId
      pmsUnitGroupId
      name
      privacyMode
      privacyModeLastChangeTimestamp
      condition
    }
    unitCleanOnCheckin
    extraPmsSimpleProperties
    idCheckStatus
    checkedInBy
    checkedOutBy
    canCheckout
    restaurantReservations {
      foodAndBeverageReservationId
      foodAndBeveragePropertyId
      foodAndBeveragePropertyName
      reservationDateTime
      status
      notes
      expectedNumberOfGuests
      actualNumberOfGuests
      tableNumbers
      createdAt
      updatedAt
}
    reservationUpsellInformation {
      originalPmsUnitGroupId
    }
  }
}
    ${PersonFragmentFragmentDoc}`;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    OverviewDetails: build.query<OverviewDetailsQuery, OverviewDetailsQueryVariables>({
      query: (variables) => ({ document: OverviewDetailsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useOverviewDetailsQuery, useLazyOverviewDetailsQuery } = injectedRtkApi;
