import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { Person, PersonGender } from "src/domain/person";
import { GuestType } from "./rows-for-table-guest-overview/rows-for-table-guest-overview";
import { IdCheckStatus } from "src/domain/reservation";
import {
  getI18nLanguage,
  twoLetterLanguageCode
} from "../../../../../components/language-selector/language-selector";
import { formatDate } from "src/utils/timezoned-date";
import { isDisabledIdentificationForCountry } from "src/utils/countries";
import { ReservationTableDto } from "src/domain/reservation-table-dto";

export enum GuestInsightInfoTypes {
  CONTACT = "CONTACT",
  PRIVATE_ADDRESS = "PRIVATE_ADDRESS",
  PREFERENCES = "PREFERENCES",
  NOTES = "NOTES",
  LEGAL_DATA = "LEGAL_DATA",
  BILLING_ADDRESS = "BILLING_ADDRESS",
  ACCOUNT = "ACCOUNT"
}

export const getGuestInsightInfoIcon = (type: GuestInsightInfoTypes) => {
  switch (type) {
    case GuestInsightInfoTypes.CONTACT:
      return <PermContactCalendarOutlinedIcon />;
    case GuestInsightInfoTypes.PRIVATE_ADDRESS:
      return <PersonPinCircleOutlinedIcon />;
    case GuestInsightInfoTypes.PREFERENCES:
      return <RoomPreferencesOutlinedIcon />;
    case GuestInsightInfoTypes.LEGAL_DATA:
      return <PolicyOutlinedIcon />;
    case GuestInsightInfoTypes.BILLING_ADDRESS:
      return <ReceiptLongOutlinedIcon />;
    case GuestInsightInfoTypes.NOTES:
      return <ChatOutlinedIcon />;
    case GuestInsightInfoTypes.ACCOUNT:
      return <AccountBoxOutlinedIcon />;
    default:
      return <></>;
  }
};

export const genderPronounsLabel = (
  gender: PersonGender,
  translation: (translationlabel: string) => string
) => {
  return `(${translation("labels__pronouns__" + gender)})`;
};

export const regionNames = new Intl.DisplayNames([getI18nLanguage()], {
  type: "region"
});

export const getGuestInsightInfoDataToShow = (
  booker: Person,
  primaryGuest: Person,
  travelBuddies: Person[],
  idCheckStatus: IdCheckStatus,
  primaryGuestSameAsBooker: boolean,
  selectedPropertyTimezone: string,
  translation: (translationlabel: string) => string,
  disabledCountriesForIdentification?: string[]
) => {
  let data = [];
  if (primaryGuestSameAsBooker) {
    data = [
      getGuestInfoToShow({
        type: primaryGuest,
        role: GuestType.SAME_GUEST as GuestType,
        translation,
        idCheckStatus,
        selectedPropertyTimezone,
        disabledCountriesForIdentification
      })
    ];
  } else {
    data = [
      getGuestInfoToShow({
        type: primaryGuest,
        role: GuestType.PRIMARY_GUEST as GuestType,
        translation,
        idCheckStatus,
        selectedPropertyTimezone,
        disabledCountriesForIdentification
      }),
      getGuestInfoToShow({
        type: booker,
        role: GuestType.BOOKER as GuestType,
        translation
      })
    ];
  }
  const travelBuddiesMapped = travelBuddies.map((buddy) =>
    getGuestInfoToShow({
      type: buddy,
      role: GuestType.TRAVEL_BUDDY as GuestType,
      translation
    })
  );

  return [...data, ...travelBuddiesMapped];
};

interface getGuestInfoToShowInterface {
  type: Person;
  role: GuestType;
  idCheckStatus?: IdCheckStatus;
  selectedPropertyTimezone?: string;
  translation: (translationlabel: string) => string;
  disabledCountriesForIdentification?: string[];
}

export const getGuestInfoToShow = (person: getGuestInfoToShowInterface) => {
  const {
    type,
    role,
    translation,
    idCheckStatus,
    selectedPropertyTimezone,
    disabledCountriesForIdentification
  } = person;
  return {
    name: type.firstName + " " + type.lastName,
    role: role,
    gender: type?.gender,
    recurring:
      role === GuestType.PRIMARY_GUEST || role === GuestType.SAME_GUEST
        ? type.isRecurring
        : undefined,
    account: role !== GuestType.TRAVEL_BUDDY ? type.hasAccount : undefined,
    preferences: role !== GuestType.TRAVEL_BUDDY ? type.guestPreferences : undefined,
    preferencesText: role !== GuestType.TRAVEL_BUDDY ? type.guestPreferencesText : undefined,
    contact: [
      type?.email,
      type?.preferredCommunicationChannel &&
        translation(
          `labels__guest__communication__channel__${type?.preferredCommunicationChannel}`
        ),
      type?.preferredLanguage &&
        translation(`labels__guest__language__${twoLetterLanguageCode(type?.preferredLanguage)}`),
      type?.phone
    ].filter((elem) => elem !== null),
    legal:
      role === GuestType.PRIMARY_GUEST || role === GuestType.SAME_GUEST
        ? ([
            type?.nationalityCountryCode && regionNames.of(type?.nationalityCountryCode),
            formatDate(type?.birthdate, selectedPropertyTimezone),
            isDisabledIdentificationForCountry(
              disabledCountriesForIdentification,
              type?.nationalityCountryCode
            )
              ? ""
              : type?.identificationNumber
          ].filter((elem) => elem !== null) as string[])
        : undefined,
    privateAddress:
      role !== GuestType.TRAVEL_BUDDY && type.address
        ? [
            [type.address?.addressLine1, type.address?.addressLine2].join(" "),
            [type.address?.postalCode, type.address?.city].join(" "),
            type.address.countryCode && regionNames.of(type.address.countryCode)
          ]
            .map((elem) => elem?.trim() as string)
            .filter((elem) => elem !== "")
        : undefined,
    billingAddress:
      role !== GuestType.TRAVEL_BUDDY && type?.billingAddress
        ? [
            type.billingAddress?.company?.name,
            [
              type.billingAddress?.address?.addressLine1,
              type.billingAddress?.address?.addressLine2
            ].join(" "),
            [type.billingAddress?.address?.postalCode, type.billingAddress?.address?.city].join(
              " "
            ),
            type.billingAddress?.address?.countryCode &&
              regionNames.of(type.billingAddress?.address?.countryCode)
          ]
            .map((elem) => elem?.trim() as string)
            .filter((elem) => elem !== "")
        : undefined,
    verified:
      role === GuestType.PRIMARY_GUEST || role === GuestType.SAME_GUEST ? idCheckStatus : undefined,
    emergencyEvacuationHelpNeeded:
      role !== GuestType.TRAVEL_BUDDY ? type.emergencyEvacuationHelpNeeded || false : undefined,
    enrolledInLoyaltyProgram:
      role !== GuestType.TRAVEL_BUDDY ? type.enrolledInLoyaltyProgram || false : undefined,
    guestNotes: role !== GuestType.TRAVEL_BUDDY ? type.guestNotes : undefined,
    frequentFlyerPrograms: role !== GuestType.TRAVEL_BUDDY ? type.frequentFlyerPrograms : undefined,
    membershipNumber: role !== GuestType.TRAVEL_BUDDY ? type.eClubMembershipNumber : undefined
  };
};

export const guestSpecificUnits = (reservation: ReservationTableDto, actorId: string) => {
  const guestPreferences =
    actorId === GuestType.BOOKER
      ? reservation?.booker?.guestPreferences
      : reservation?.primaryGuest?.guestPreferences;

  return guestPreferences && Array.isArray(guestPreferences?.SPECIFIC_UNIT)
    ? [...guestPreferences.SPECIFIC_UNIT]
    : [];
};
