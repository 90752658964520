import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ReservationDetailsDTO } from "../../../../../../store/endpoints/reservation-table.endpoints";
import { Box, Grid, Paper } from "@mui/material";
import {
  Chip,
  ChipProps,
  EmptyState,
  EmptyStateSize,
  Heading3,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { grey } from "@mui/material/colors";
import { RestaurantReservationStatus } from "../../../../../../domain/reservation-table-dto";
import { ActionMenu } from "../../../../../../components/action-menu/action-menu";
import { SevenroomsWidget } from "../../../../../../components/sevenrooms-widget/sevenrooms-widget";
import { useSevenRoomsWidgetConfig } from "../../../../../../hooks/use-configuration";
import { loadScriptOnce } from "../../../../../../utils/file-utils";
import { useIsMobile } from "../../../../../../hooks/use-is-mobile";

interface ReservationDetailsRestaurantsProps {
  reservation: ReservationDetailsDTO;
}

// This is a specific use case for the restaurant reservation details. Do not use this for other dates formatting.
export const getRestaurantReservationDate = (dateTime: string) => {
  if (!dateTime) {
    return "";
  }

  const [date] = dateTime.split("T");
  const [year, month, day] = date.split("-");

  return `${day}/${month}/${year}`;
};

// This is a specific use case for the restaurant reservation details. Do not use this for other dates formatting.
export const getRestaurantReservationTime = (dateTime: string) => {
  if (!dateTime) {
    return "";
  }

  const [, time] = dateTime.split("T");
  const [hours, minutes] = time.split(":");

  return `${hours}:${minutes}`;
};

export const ReservationDetailsRestaurants: FC<ReservationDetailsRestaurantsProps> = ({
  reservation
}) => {
  const { t } = useTranslationWrapper();
  const [selectedReservationId, setSelectedReservationId] = useState<string | undefined>();
  const sevenRoomsWidgetConfig = useSevenRoomsWidgetConfig();
  const isMobile = useIsMobile();

  const getChipColor = (status?: RestaurantReservationStatus): ChipProps["color"] => {
    switch (status) {
      case RestaurantReservationStatus.Arrived:
        return "info";
      case RestaurantReservationStatus.Booked:
        return "info";
      case RestaurantReservationStatus.Canceled:
        return "error";
      case RestaurantReservationStatus.NoShow:
        return "error";
      case RestaurantReservationStatus.Completed:
        return "success";
      case RestaurantReservationStatus.Confirmed:
        return "info";
      case RestaurantReservationStatus.Seated:
        return "success";
      default:
        return "default";
    }
  };

  const person = reservation.primaryGuest;

  const menuItems = useMemo(() => {
    return [
      {
        label: t("labels__edit"),
        id: "edit"
      }
    ];
  }, [t]);

  useEffect(() => {
    if (sevenRoomsWidgetConfig?.url) {
      loadScriptOnce(sevenRoomsWidgetConfig?.url);
    }
  }, [sevenRoomsWidgetConfig?.url]);

  const handleAction = useCallback((selectedOptionId: string, restaurantReservationId?: string) => {
    switch (selectedOptionId) {
      case "edit":
        setSelectedReservationId(restaurantReservationId);
        console.log("edit");
        break;
      case "cancel":
        console.log("cancel");
        break;
    }
  }, []);

  return (
    <Box mt={2}>
      {!!reservation?.restaurantReservations?.length && (
        <Paper sx={{ p: 3 }} elevation={0}>
          <Heading3 sx={{ marginBottom: 1.5 }}>{t("labels__restaurant_reservations")}</Heading3>
          <Box sx={{ overflowX: "auto" }}>
            <Box sx={{ minWidth: isMobile ? "220%" : "unset", width: "100%" }}>
              <Grid container alignItems="center" pb={0.5}>
                <Grid item xs={2.5} pl={1.5}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_venue_name")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={2}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_date")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={1}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_guests")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={1}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_table_number")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={1.5}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_created_at")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={2}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_note")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={0.5}></Grid>
              </Grid>
              <Grid container>
                {reservation.restaurantReservations?.map((restaurantReservation) => (
                  <Grid
                    item
                    xs={12}
                    key={`${restaurantReservation?.foodAndBeveragePropertyId}_${restaurantReservation?.reservationDateTime}`}
                    paddingBottom={1.5}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        background: grey[100],
                        borderColor: grey[100],
                        borderRadius: "5px",
                        pr: 1
                      }}
                      variant="outlined"
                    >
                      <Grid
                        container
                        direction="row"
                        py={1.5}
                        alignItems="center"
                        position="relative"
                      >
                        <Grid item xs={2.5} md={2.5} pl={2}>
                          <ParagraphSmall color="text.primary">
                            {restaurantReservation?.foodAndBeveragePropertyName}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={2}>
                          {restaurantReservation?.reservationDateTime && (
                            <>
                              {getRestaurantReservationDate(
                                restaurantReservation?.reservationDateTime
                              )}
                              {" | "}
                              {getRestaurantReservationTime(
                                restaurantReservation?.reservationDateTime
                              )}
                            </>
                          )}
                        </Grid>
                        <Grid item xs={1} pl={0.5}>
                          <ParagraphSmall color="text.primary">
                            {restaurantReservation?.expectedNumberOfGuests ?? ""}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={1} pl={0.5}>
                          <ParagraphSmall color="text.primary">
                            {restaurantReservation?.tableNumbers?.map((table, index) => {
                              return `${table}${
                                restaurantReservation?.tableNumbers?.length &&
                                index === restaurantReservation?.tableNumbers?.length - 1
                                  ? ""
                                  : ", "
                              }`;
                            })}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={1.5} pl={0.5}>
                          <ParagraphSmall color="text.primary">
                            <>
                              {getRestaurantReservationDate(restaurantReservation?.createdAt ?? "")}
                              {" | "}
                              {getRestaurantReservationTime(restaurantReservation?.createdAt ?? "")}
                            </>
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={2} pl={0.5}>
                          <ParagraphSmall color="text.primary">
                            {restaurantReservation?.notes ?? ""}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={1.5} textAlign="center">
                          <Chip
                            size="small"
                            variant="outlined"
                            color={getChipColor(restaurantReservation?.status)}
                            label={t(
                              `labels__restaurant_reservation_status_${restaurantReservation?.status}`
                            )}
                          />
                        </Grid>
                        <Grid item xs={0.5}>
                          <ActionMenu
                            items={menuItems}
                            handleAction={(item) =>
                              handleAction(
                                item,
                                restaurantReservation?.foodAndBeverageReservationId
                              )
                            }
                            disabled={!menuItems.length || !sevenRoomsWidgetConfig}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Paper>
      )}
      {!reservation?.restaurantReservations?.length && (
        <Box>
          <EmptyState
            title={t("labels__reservation__details__restaurant_no_reservations")}
            size={EmptyStateSize.LARGE}
          />
        </Box>
      )}

      {selectedReservationId && sevenRoomsWidgetConfig && (
        <SevenroomsWidget
          guestIdentityId={person.guestIdentityId}
          firstName={person.firstName}
          lastName={person.lastName}
          email={person.email}
          phone={person.phone}
          locale={person.preferredLocale}
          hotelReservationArrivalDate={reservation.arrival ?? ""}
          hotelReservationDepartureDate={reservation.departure ?? ""}
          amountOfPeople={reservation.adultsCount}
          //TODO check if there is admin mode ?
          mode="GUEST"
          restaurantReservationId={selectedReservationId}
          toggle={!!selectedReservationId}
          reservationId={reservation.id}
          onClose={(toggle: boolean) => {
            if (toggle) {
              setSelectedReservationId(undefined);
            }
            console.error("Should not happen.");
          }}
          apiKey={sevenRoomsWidgetConfig?.apiKey}
        />
      )}
    </Box>
  );
};
